import { get, map } from 'lodash'
import React from 'react'
import { Fade, Flip } from 'react-reveal'

import {
  Columns,
  Content,
  Container,
  Heading,
  Section,
} from 'react-bulma-components'
import {
  Base,
  BasePreview,
  HtmlContent,
  Image as CommonImage,
} from 'components/common'
import Hero from 'components/hero'


export default function AboutPageTemplate(props) {
  const {
    aboutText,
    accreditations = [],
    heroHeading,
    heroImage,
    previewMode,
  } = props

  const BaseComponent = previewMode ? BasePreview : Base
  const heroBackground = get(heroImage, 'childImageSharp.fluid.src')
    || heroImage.value|| null

  return (
    <BaseComponent>
      <Hero backgroundImage={heroBackground} size="medium">
        <Container
          className="padding-vertical-32"
          color="primary"
        >
          <Flip bottom>
            <Content size="large">
              <Heading className="has-text-white" size={1}>
                {heroHeading}
              </Heading>
            </Content>
          </Flip>
        </Container>
      </Hero>
      <Fade cascade>
        <Section>
          <Container className="padding-vertical-32">
            <Content size="medium">
              {aboutText && <HtmlContent content={aboutText} />}
            </Content>
          </Container>
        </Section>
        <Section>
          <Container className="has-text-centered padding-vertical-32">
            <Columns>
              {map(accreditations, (props, index) => (
                <Columns.Column key={index} mobile={{ size: 12 }} size={4}>
                  <div style={{ margin: '0 auto', width: 240 }}>
                  <CommonImage imageInfo={{ image: props.image }} />
                  </div>
                </Columns.Column>
              ))}
            </Columns>
          </Container>
        </Section>
      </Fade>
    </BaseComponent>
  )
}
